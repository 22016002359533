<template>
  <v-card raised elevation="6" class="mt-1">
    <v-card-title>
      <h4
        style="
          font-size: 15px;
          text-transform: uppercase;
          font-weight: 100;
          text-decoration: underline;
        "
      >
        {{ $t("sales.byServices") }}
      </h4>
      <v-spacer></v-spacer>
      <v-btn @click="graphic = !graphic" :outlined="graphic" height="30px"
        >Tabla</v-btn
      >
    </v-card-title>
    <v-card-text v-if="graphic"><DoughnutChartServices /> </v-card-text>
    <v-data-table
      v-else
      :headers="headersByService"
      :items="list"
      :items-per-page="5"
      id="salesByService-table"
    >
      <template v-slot:item.type="{ item }">
        {{ item.type }}
      </template>
      <template v-slot:item.type_quantity="{ item }">
        {{ item.type_quantity }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "SalesByService",
  components: {
    DoughnutChartServices: () =>
      import("@/components/stats/sales/graphics/SalesByServiceGraphic"),
  },
  ...mapState("stats", ["lists"]),

  computed: {
    //METODO / VARIABLE
    list() {
      return this.$store.state.stats.lists.salesByService;
    },
  },
  data() {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
      graphic: true,
      headersByService: [
        {
          text: this.$t("type"),
          align: "center",
          sortable: false,
          value: "type",
        },
        {
          text: this.$t("quantity"),
          align: "center",
          sortable: false,
          value: "type_quantity",
        },
      ],
    };
  },
};
</script>

<style></style>
